import * as Phaser from "phaser";
import * as spine from "@esotericsoftware/spine-phaser";

const cardImages = {
  Common: "/assets/noi/c02.png",
  Uncommon: "/assets/noi/bf02.png",
  Rare: "/assets/noi/sp02.png",
  Epic: "/assets/noi/wk02.png",
  legendery: "/assets/noi/wk02.png",
};
const prizeToImage = {
  Cash: "/assets/cards-icons/cash.png",
  "Bonus Funds": "/assets/cards-icons/bonus_funds.png",
  "Free Spins": "/assets/cards-icons/free_spins.png",
  "Free Bet": "/assets/cards-icons/free_bets.png",
  FunCoins: "/assets/cards-icons/win_coins.png",
  "Golden Chips": "/assets/cards-icons/golden_chips.png",
  "Wheel Spins": "/assets/cards-icons/wheel_spins.png",
  "Loyalty Points": "/assets/cards-icons/loyalty_points.png",
  "Shop Points": "/assets/cards-icons/shop_points.png",
  Multiplier: "/assets/cards-icons/multiplier.png",
};
const loot = document.querySelector(".loot");

export class SpineDemo extends Phaser.Scene {
  constructor() {
    super({ key: "SpineDemo" });
    this.isAnimationPlaying = false;
    this.cardIndex = 0;
    this.openedCards = []; // Array pentru a stoca cardurile deschise
    this.gameOver = false; // Variabilă nouă pentru a indica sfârșitul jocului
    this.selectedPullType = null; // Variabilă pentru stocarea tipului de pull selectat
    this.hasMultiplier = false; // Adaugă o variabilă pentru a urmări dacă premiul 'Multiplier'
    this.numRounds = 0; // Proprietate pentru a stoca numărul de runde
    this.isLegendary = false; // Adaugă o variabilă pentru a urmări dacă este selectat tipul 'legendary'
    this.animaionSkip = false;
    this.isSvgAdded = false; // Variabilă pentru a urmări dacă SVG-ul a fost adăugat
    this.isFirstClick = true; // Flag pentru primul click
    // this.this.deviceInfo = deviceInfo; = deviceInfo;

  }


  preload() {
    this.load.image("particle", "assets/white-flare.png");

    // Get chest number from URL query string
    const urlParams = new URLSearchParams(window.location.search);
    let chestNumber = parseInt(urlParams.get("chest"), 10);

    // Set default chest number to 1 if parameter is missing or out of range
    if (!chestNumber || chestNumber < 1 || chestNumber > 5) {
      chestNumber = 1;
    }

    console.log("chestNumber", chestNumber);
    const chestPath = `assets/chest_${chestNumber}/`;

    this.load.spineBinary("lootbox-data", `${chestPath}skeleton.skel`);
    this.load.spineAtlas("lootbox-atlas", `${chestPath}export.atlas`);
    this.load.audio("open2Sound", `/assets/chest2.mp3`);
    this.load.audio("cash", `/assets/cash.mp3`);
    this.load.audio("spin", `/assets/paper-spin-86544.mp3`);
    this.load.audio("boom", `/assets/spellcast-46164.mp3`);
    // this.load.audio("normal", `/assets/normal2.mp3`);
    this.load.audio("sumar", `/assets/sumar2.mp3`);
    this.load.audio("legendary", `/assets/legendary.mp3`);
    this.load.audio("clap", `/assets/clap3.mp3`);
    this.load.audio("get_card", `/assets/get_card.mp3`);
    this.load.audio("loop", `/assets/loop.mp3`);
    // this.load.video("explosion", "/explozie-33.mov");
    // this.load.video("bg-video", "/3-1.mov");
    // Load the new disco_fx spine animation
    // this.load.spineBinary("disco-fx-data", "assets/disco_export_test_1/skeleton.skel");
    // this.load.spineAtlas("disco-fx-atlas", "assets/disco_export_test_1/disco_fx.atlas");
    this.load.spineBinary("particle-explosion-data", "assets/chest_particle_explosion_v3/skeleton.skel");
  this.load.spineAtlas("particle-explosion-atlas", "assets/chest_particle_explosion_v3/card_particles_explode_export.atlas");
  // Load the particle implosion Spine animation
  this.load.spineBinary("particle-implosion-data", "assets/particles_implosion_v3/skeleton.skel");
  this.load.spineAtlas("particle-implosion-atlas", "assets/particles_implosion_v3/card_particles_export.atlas");

  this.load.spineBinary("premium-data", "assets/frame_and particles_v2/crd_spin.skel");
  this.load.spineAtlas("premium-atlas", "assets/frame_and particles_v2/card_particles_v3.atlas");

  // this.load.spineBinary("fireworks-data", "assets/fireworks/crd_spin.skel");
  // this.load.spineAtlas("fireworks-atlas", "assets/fireworks/fireworks.atlas");

  this.load.image('card_1', '/assets/card_numbered_2.png');
  this.load.image('card_2', '/assets/card_numbered_2.png');
  // this.load.atlas('flares', 'assets/flares.png', '/flares.json');

  }
  create() {
    const self = this; // Păstrează o referință la instanța scenei
    // game.time.advancedTiming = true;
    // this.cameras.main.setZoom(0.4);
    this.sound.pauseOnBlur = false; // Opțional, pentru a preveni oprirea sunetului când fereastra pierde focusul
    const muteButton = document.getElementById("muteButton");
    let isMuted = false;
    muteButton.addEventListener("click", () => {
      isMuted = !isMuted;
      this.sound.mute = isMuted;
      muteButton.classList.toggle("muted", isMuted);
    });
    const centerX = this.cameras.main.width / 2;
    const centerY = this.cameras.main.height / 2;
    // const lootbox = this.add.spine(
    //   centerX + 100,
    //   centerY + 260,
    //   "lootbox-data",
    //   "lootbox-atlas"
    // );
    let lootboxX = centerX + 100;
    let lootboxY = centerY + 260;


    // Ajustează pozițiile pentru dispozitive mobile
    if (window.innerWidth < 1024) {
        lootboxX = centerX;
        lootboxY = centerY + 950;
        
    } 
    this.fpsText = this.add.text(10, -710, 'FPS: 0', { font: '76px Arial', fill: '#00ff00' });

    const lootbox = this.add.spine(
        lootboxX,
        lootboxY,
        "lootbox-data",
        "lootbox-atlas"
    );
    // const shape1 = new Phaser.Geom.Circle(0, 0, 160);
    // const shape2 = new Phaser.Geom.Ellipse(0, 0, 500, 150);
    // const shape3 = new Phaser.Geom.Rectangle(-150, -150, 300, 300);
    // const shape4 = new Phaser.Geom.Line(-150, -150, 150, 150);
    // const shape5 = new Phaser.Geom.Triangle.BuildEquilateral(0, -140, 300);

    // const emitter = this.add.particles(0, 300, 'flares', {
    //     frame: { frames: [ 'red', 'green', 'blue', 'white', 'yellow' ], cycle: true },
    //     blendMode: 'ADD',
    //     lifespan: 500,
    //     quantity: 1,
    //     scale: { start: 1, end: 0.2 }
    // });

    // emitter.addEmitZone({ type: 'edge', source: shape1, quantity: 64, total: 1 });
    // emitter.addEmitZone({ type: 'edge', source: shape2, quantity: 64, total: 1 });
    // emitter.addEmitZone({ type: 'edge', source: shape3, quantity: 64, total: 1 });
    // emitter.addEmitZone({ type: 'edge', source: shape4, quantity: 64, total: 1 });
    // emitter.addEmitZone({ type: 'edge', source: shape5, quantity: 64, total: 1 });
   //if mobile resize lootbox
  //  emitter.setScale(3);
  if (globalDeviceInfo.smallerSide === 834) {
    // Configurații pentru dispozitive cu globalDeviceInfo.smallerSide egal cu 834
    this.card1 = this.add.image(centerX + 70, centerY + 360, 'card_1').setScale(0).setAlpha(0); // Ajustează scala și poziția după necesități
    this.card2 = this.add.image(centerX + 100, centerY + 360, 'card_2').setScale(0).setAlpha(0); 

    console.log('Card 1 position:', this.card1.x, this.card1.y);
    console.log('Card 2 position:', this.card2.x, this.card2.y);
    console.log('Card 1 scale:', this.card1.scale);
    console.log('Card 2 scale:', this.card2.scale);
    console.log('Screen dimensions:', this.cameras.main.width, this.cameras.main.height);
    console.log('Device pixel ratio:', window.devicePixelRatio);

    this.card1.setScale(0.3);
    this.card2.setScale(0.3);
    this.card1.x -= 10; 
    this.card1.y += 5; 
    this.card1.angle = 345; 
    this.roundsText = this.add.text(centerX + 100, centerY + 360, this.numRounds.toString(), {
        fontWeight: 'bold',
        fontSize: '70px',
        color: '#fff',
        fontFamily: '"Berlin Sans FB Demi"',
        letterSpacing: '1.1px',
        shadow: {
            offsetX: 2,
            offsetY: 2,
            color: '#000',
            blur: 2,
            stroke: true,
            fill: true
        },
        stroke: '#000',
        strokeThickness: 8
    }).setOrigin(0.5).setScale(0).setAlpha(0);

} else if (globalDeviceInfo.smallerSide  <= 1024) {
      this.card1 = this.add.image(centerX + 120, centerY + 950,  'card_1').setScale(0).setAlpha(0); // Ajustează scala după necesități
      this.card2 = this.add.image(centerX + 170, centerY + 950, 'card_2').setScale(0).setAlpha(0); 

      console.log('Card 1 position:', this.card1.x, this.card1.y);
      console.log('Card 2 position:', this.card2.x, this.card2.y);
      console.log('Card 1 scale:', this.card1.scale);
      console.log('Card 2 scale:', this.card2.scale);
      console.log('Screen dimensions:', this.cameras.main.width, this.cameras.main.height);
      console.log('Device pixel ratio:', window.devicePixelRatio);



      this.card1.setScale(2.3);
      this.card2.setScale(2.3);
      this.card1.x -= 30; // translateX(-14px)
      this.card1.y += 5; // translateY(2px)
      this.card1.angle = 345; // rotate(345deg)
      this.roundsText = this.add.text(centerX + 170, centerY + 950, this.numRounds.toString(), {
        fontWeight: 'bold',
        fontSize: '80px',
        color: '#fff',
        fontFamily: '"Berlin Sans FB Demi"',
        letterSpacing: '1.1px',
        shadow: {
            offsetX: 2,
            offsetY: 2,
            color: '#000',
            blur: 2,
            stroke: true,
            fill: true
        },
        stroke: '#000',
        strokeThickness: 10
        
    }).setOrigin(0.5).setScale(0).setAlpha(0);
    } else {
      this.card1 = this.add.image(centerX + 135, centerY + 250,  'card_1').setScale(0).setAlpha(0); // Ajustează scala după necesități
      this.card2 = this.add.image(centerX + 140, centerY + 250, 'card_2').setScale(0).setAlpha(0); 
      // this.card1.setScale(1.5);
      // this.card2.setScale(1.5);
      
      this.card1.x -= 10; // translateX(-14px)
      this.card1.y += 5; // translateY(2px)
      this.card1.angle = 345; // rotate(345deg)
      this.roundsText = this.add.text(centerX + 140, centerY + 250, '7', {
        fontWeight: 'bold',
        fontSize: '40px',
        color: '#fff',
        fontFamily: '"Berlin Sans FB Demi"',
        letterSpacing: '1.1px',
        shadow: {
            offsetX: 2,
            offsetY: 2,
            color: '#000',
            blur: 2,
            stroke: true,
            fill: true
        },
        stroke: '#000',
        strokeThickness: 2
    }).setOrigin(0.5).setScale(0).setAlpha(0);
    }

   
  
  //   const fireworks = this.add.spine(centerX, centerY, "fireworks-data", "fireworks-atlas");
  //  fireworks.setVisible(false); // Ascunde animația la început

    const particleExplosion = this.add.spine(centerX, centerY, "particle-explosion-data", "particle-explosion-atlas");
    particleExplosion.setVisible(false); // Ascunde animația la început
    const premium = this.add.spine(centerX, centerY, "premium-data", "premium-atlas");
    premium.setVisible(false); // Ascunde animația la început

    const particleImplosion = this.add.spine(centerX, centerY, "particle-implosion-data", "particle-implosion-atlas");
    particleImplosion.setVisible(false); // Ascunde animația la început
    // particleImplosion.setScale(0.2); // Ajustează scala animației

   const targetWidth = 400; // Lățimea dorită
    const targetHeight = 400; // Înălțimea dorită
    const scaleX = targetWidth / particleExplosion.width;
    const scaleY = targetHeight / particleExplosion.height;
    // fireworks.setScale(scaleX, scaleY);
    particleExplosion.setScale(scaleX, scaleY);
    // const discoFX = this.add.spine(centerX, centerY, "disco-fx-data", "disco-fx-atlas");
    particleExplosion.setPosition(centerX + 50, centerY + 180); // Ajustează valoarea pentru poziția dorită
    // fireworks.setPosition(centerX + 50, centerY + 180); // Ajustează valoarea pentru poziția dorită
  //   if (window.innerWidth < 600) {
  //     particleExplosion.setPosition(centerX , centerY + 50); // Ajustează valoarea pentru poziția dorită
  //     particleImplosion.setPosition(centerX -20, centerY + 150);

      
  // } 
  
  // else {
  //   particleExplosion.setPosition(centerX + 50, centerY + 130); // Ajustează valoarea pentru poziția dorită
  //   particleImplosion.setPosition(centerX + 50, centerY + 280);

  // }
  const isAndroid = document.body.classList.contains('android');
  if(isAndroid) {
    particleImplosion.setScale(1)
    particleExplosion.setPosition(centerX - 150, centerY - 800); // Ajustează valoarea pentru poziția dorită
    particleImplosion.setPosition(centerX - 150, centerY + 150);
    // fireworks.setPosition(centerX - 50, centerY + 150); // Ajustează valoarea pentru poziția dorită
    premium.setScale(1.8)
    premium.setPosition(centerX - 890, centerY - 1000); 
  }else if (window.innerWidth < 768) {
  
    particleImplosion.setScale(1)
    particleExplosion.setPosition(centerX - 150, centerY - 800); // Ajustează valoarea pentru poziția dorită
    particleImplosion.setPosition(centerX - 150, centerY + 150);
    // fireworks.setPosition(centerX - 50, centerY + 150); // Ajustează valoarea pentru poziția dorită
    premium.setScale(1.8)
    premium.setPosition(centerX - 870, centerY - 950); // Ajustează valoarea pentru 4K
  }else if (globalDeviceInfo.smallerSide === 1440) { // Condiție pentru 4K (3840px lățime)
    particleImplosion.setScale(0.2)
    particleExplosion.setScale(0.2)
    // fireworks.setScale(0.2)
    premium.setPosition(centerX - 56, centerY - 45); // Ajustează valoarea pentru 4K

    premium.setScale(0.25)

    console.log("4k2sadasdas")
    particleExplosion.setPosition(centerX + 50, centerY + 120); // Ajustează valoarea pentru 4K
    // fireworks.setPosition(centerX + 50, centerY + 80); // Ajustează valoarea pentru 4K
     particleImplosion.setPosition(centerX +  50, centerY + 240); // Ajustează valoarea pentru 4K
    
    
  }
  else if (window.innerWidth == 2560 ) { // Condiție pentru 4K (3840px lățime)
    particleImplosion.setScale(0.2)
    particleExplosion.setScale(0.2)
    // fireworks.setScale(0.2)
    premium.setPosition(centerX - 50, centerY - 45); // Ajustează valoarea pentru 4K

    premium.setScale(0.25)

    console.log("4k2sadasdas")
    particleExplosion.setPosition(centerX + 50, centerY + 120); // Ajustează valoarea pentru 4K
    // fireworks.setPosition(centerX + 50, centerY + 80); // Ajustează valoarea pentru 4K
     particleImplosion.setPosition(centerX +  50, centerY + 240); // Ajustează valoarea pentru 4K
    
    
  } else if (window.innerWidth > 2000) { // Condiție pentru 4K (3840px lățime)
    premium.setScale(0.25)
    premium.setPosition(centerX - 30, centerY - 40); // Ajustează valoarea pentru 4K
    particleImplosion.setScale(0.2)
    particleExplosion.setScale(0.2)
    particleExplosion.setPosition(centerX + 70, centerY + 130); // Ajustează valoarea pentru 4K
    particleImplosion.setPosition(centerX +  70, centerY + 280); // Ajustează valoarea pentru 4K
    // fireworks.setScale(0.2)
  } else if (window.innerWidth == 1920) { // Condiție pentru 4K (3840px lățime)
    premium.setScale(0.25)
    premium.setPosition(centerX - 42, centerY - 40); // Ajustează valoarea pentru 4K
    particleImplosion.setScale(0.2)
    particleExplosion.setScale(0.2)
    particleExplosion.setPosition(centerX + 70, centerY + 150); // Ajustează valoarea pentru 4K
    particleImplosion.setPosition(centerX +  60, centerY + 280); // Ajustează valoarea pentru 4K
    // fireworks.setScale(0.2)
  }else {
    particleExplosion.setScale(0.2)
    premium.setScale(0.2)
    particleImplosion.setScale(0.2)
    particleExplosion.setPosition(centerX + 50, centerY + 130); // Ajustează valoarea pentru poziția dorită
    particleImplosion.setPosition(centerX + 55, centerY + 280);
    // fireworks.setScale(0.2)
  }

    particleExplosion.y -= 200; // Ajustează această valoare după nevoie
    particleImplosion.y -= 200;

    // console.log(`particleExplosion position: (${particleExplosion.x}, ${particleExplosion.y})`);

    //   this.add.particles(0, -32, 'particle', {
    //     x: centerX,
    //     y: centerY,
    //     speed: 200,
    //     scale: { start: 1, end: 0 },
    //     blendMode: 'ADD',
    //     lifespan: 600,

    // });

    // lootbox.customMaterial.texture.setFilter(Phaser.Textures.FilterMode.LINEAR);
    // this.scale.resize(window.innerWidth, window.innerHeight);

  

    document.getElementById("regularButton").addEventListener("click", () => {
      const buttonContainer = document.getElementById("buttonContainer");
      console.log("regular");
      this.selectedPullType = "regular";
      buttonContainer.classList.add("hide");
      this.fetchAndHandleRounds("regular");
    });

    document.getElementById("uncommonButton").addEventListener("click", () => {
      console.log("uncommon");
      this.selectedPullType = "uncommon";
      const buttonContainer = document.getElementById("buttonContainer");
      buttonContainer.classList.add("hide");
      this.fetchAndHandleRounds("uncommon");
    });

    document
      .getElementById("multiplierButton")
      .addEventListener("click", () => {
        console.log("multiplier");
        this.selectedPullType = "multiplier";
        const buttonContainer = document.getElementById("buttonContainer");
        buttonContainer.classList.add("hide");

        this.fetchAndHandleRounds("multiplier"); // Fetch rounds for multiplier
      });

    document.getElementById("legendaryButton").addEventListener("click", () => {
      console.log("legendary");
      this.selectedPullType = "legendary";
      const buttonContainer = document.getElementById("buttonContainer");
      buttonContainer.classList.add("hide");
      this.fetchAndHandleRounds("legendary"); // Fetch rounds for legendary
    });
    // lootbox.scale = 0.4;
    // if (window.innerWidth < 800) {
    //   lootbox.scale = 1;
    //   this.cameras.main.setZoom(2);
    // } 
    // else {
    //   lootbox.scale = 1.5;
    
    // }
    
    if (globalDeviceInfo.smallerSide  <= 355 && isAndroid) {
      // Telefoane mici
      lootbox.scale = 3;
      
      this.cameras.main.setZoom(.5);
      particleExplosion.scale = 1;
      premium.scale = 2.2;
    premium.setPosition(centerX - 1080, centerY - 1150); 
  }
    else if (globalDeviceInfo.smallerSide  <= 360 && isAndroid) {
      // Telefoane mici
      lootbox.scale = 3;
      
      this.cameras.main.setZoom(.5);
      particleExplosion.scale = 1;
  }
  else if (globalDeviceInfo.smallerSide <= 384 && isAndroid) {
    // Telefoane mari și tablete mici
    lootbox.scale = 3;
    this.cameras.main.setZoom(.5);
    premium.scale = 1.6;
    premium.setPosition(centerX - 820, centerY - 950); 

}else if (globalDeviceInfo.smallerSide <= 393 && isAndroid) {
  // Telefoane mari și tablete mici
  lootbox.scale = 3;
  this.cameras.main.setZoom(.5);
  premium.scale = 1.6;
  premium.setPosition(centerX - 810, centerY - 920); 

}
else if (globalDeviceInfo.smallerSide <= 412 && isAndroid) {
  // Telefoane mari și tablete mici
 
  // lootbox.setPosition(centerX,centerY + 1150);
  lootbox.scale = 3;
  this.cameras.main.setZoom(.5);
  premium.scale = 1.5;
  premium.setPosition(centerX - 750, centerY - 850); 

}
else if (globalDeviceInfo.smallerSide <= 448 && isAndroid) {
  // Telefoane mari și tablete mici
  lootbox.scale = 3;
  this.cameras.main.setZoom(.5);
  premium.scale = 1.5;
  premium.setPosition(centerX - 750, centerY - 850); 

}
else if (globalDeviceInfo.smallerSide == 834 && !isAndroid) {

  particleExplosion.setPosition(centerX - 70, centerY -320); // Ajustează valoarea pentru 4K
  particleImplosion.setPosition(centerX - 70, centerY );
  premium.scale = .5;
  premium.setPosition(centerX- 285 , centerY - 290); 

}
else if (window.innerWidth < 800) {
      // Telefoane mari și tablete mici
      lootbox.scale = 3.5;
      
      this.cameras.main.setZoom(.5);
      particleExplosion.scale = 1;
  } else if (window.innerWidth < 1200) {
      // Tablete mari și laptopuri mici
      lootbox.scale = 1;
      this.cameras.main.setZoom(1);
  } else if (window.innerWidth < 1600) {
      // Laptopuri standard și monitoare mici
      lootbox.scale = .5;
      this.cameras.main.setZoom(1);
      particleExplosion.scale = .2;

  }else if (window.innerWidth <= 1920) {
    // Laptopuri standard și monitoare mici
    lootbox.scale = .5;
    this.cameras.main.setZoom(1);
    particleExplosion.scale = .2;

} 
  else if (window.innerWidth <= 2560 ) {
    // Laptopuri standard și monitoare mici
    lootbox.scale = .5;
    this.cameras.main.setZoom(1);
    particleExplosion.scale = .2;

}else if (window.innerWidth < 3700) {
      // Laptopuri mari și monitoare medii
      lootbox.scale = .5 ;
      // this.cameras.main.setZoom(0.2);
      particleExplosion.scale = .2;

  }else if (window.innerWidth >= 3800) {
    // Laptopuri mari și monitoare medii
    lootbox.scale = .5;
    this.cameras.main.setZoom(1);
    particleExplosion.scale = .2;

}
  else{
      // Monitoare mari
      lootbox.scale = .5;
      this.cameras.main.setZoom(1);
  }
  
  // this.sound.play("loop", { volume: 0.1, loop: true });

    if (this.sys.game.device.os.iPad) {
      console.log("Rulează pe ipad");
      this.cameras.main.setZoom(1);
      lootbox.setPosition(centerX,centerY + 350 );
      var videobackgroundDiv = document.querySelector(".video-background");
      videobackgroundDiv.style.display = "none";
    }

    if (this.sys.game.device.os.macOS) {
      console.log("Rulează pe nac");
      // this.cameras.main.setZoom(1);
      // lootbox.scale = 0.8;
    }
    if (this.sys.game.device.os.iPhone) {
      console.log("Rulează pe iphone");
    }

  
    // const multiplierText2 = document.querySelector(".multiplier-text2");

    this.input.on("pointerdown", () => {
      if (self.isAnimationPlaying) {
        // Dacă animațiile sunt în curs, adaugă clasa 'skip-animation' pe body
        document.body.classList.add('skip-animation');
       
        return;
      }
      // this.isAnimationPlaying = true;

      // Play the scale-in-center animation for both cards
     
      if (!self.isAnimationPlaying) {
        // Dacă animațiile nu sunt în curs, adaugă clasa 'skip-animation' pe body
        document.body.classList.remove('skip-animation');
             

      
      }
      if (self.isAnimationPlaying || self.gameOver) {
        // Verifică dacă jocul s-a încheiat
        document.body.classList.remove('skip-animation');

        return;
      }
      // console.log("animation playng",self.isAnimationPlaying);
      
      function finalizeAnimations() {
        // Selectează toate elementele care au animații în curs

        const animatedElements = document.querySelectorAll('.object');
        animatedElements.classList.add("notransition");
        // Iterează peste toate elementele animate și modifică stilul pentru a finaliza animația
        animatedElements.forEach(el => {
          // Forțează animația să sară la ultimul keyframe
          el.style.animationPlayState = 'paused'; // Oprește animația
          let computedStyle = window.getComputedStyle(el);
          let animationName = computedStyle.animationName;
      
          // Setează un keyframe specific care reprezintă finalul animației
          el.style.animation = `${animationName} 0.001s 1 forwards`;
          el.offsetHeight; // Reflow pentru a asigura aplicarea stilurilor
          el.style.animation = ''; // Resetează stilul de animație dacă este necesar
        });
      
        // Resetează flag-ul de control
        self.isAnimationPlaying = false;
      }
      if (this.numRounds <= 0) {
        console.log("No more rounds available.");
        return; // Opriți procesarea dacă nu mai sunt runde disponibile
      }
      this.numRounds--; // Scade numărul de runde
      self.isAnimationPlaying = true;
      // this.updateRoundsText(); // Actualizează textul pe scenă

      const backFace = document.querySelector(".overlay");
      console.log('Card 1 position:', this.card1.x, this.card1.y);
      console.log('Card 2 position:', this.card2.x, this.card2.y);
      console.log('Card 1 scale:', this.card1.scale);
      console.log('Card 2 scale:', this.card2.scale);
      console.log('Screen dimensions:', this.cameras.main.width, this.cameras.main.height);
      console.log('Device pixel ratio:', window.devicePixelRatio);

      function animateValue(element, start, end, duration) {
        const isSkipAnimation = document.body.classList.contains('skip-animation');
        const adjustedDuration = isSkipAnimation ? 1 : duration;
        const range = end - start;
        const stepTime = 10; // milliseconds per step
        const steps = adjustedDuration / stepTime; // total number of steps
        const increment = range / steps; // value to increment each step to ensure we finish on time
    
        let current = start;
        const timer = setInterval(() => {
            // Verifică din nou în timpul animației dacă clasa 'skip-animation' a fost adăugată
            if (document.body.classList.contains('skip-animation')) {
                element.textContent = end; // Setează valoarea finală
                clearInterval(timer); // Oprește animația
                return;
            }
    
            current += increment;
            // Ensure we do not exceed the end value or drop below the start value
            if (
                (increment > 0 && current > end) ||
                (increment < 0 && current < start)
            ) {
                element.textContent = end; // Ensure the final number is exactly what it should be
                clearInterval(timer);
            } else {
                element.textContent = Math.floor(current); // Update with the current count, rounded down
            }
        }, stepTime);
    }
      // this.playDiscoFX(discoFX);
      // var videobuild = document.querySelector(".buildup video");
      // var buildupDiv = document.querySelector(".buildup");2
      // var videoexplision = document.querySelector(".explosion video");
      // var videoexplisionDiv = document.querySelector(".explosion");
      var videoshadow = document.querySelector(".buildup-glow video");
      var videoshadowdiv = document.querySelector(".buildup-glow");
      var videobackground = document.querySelector(".video-background video");
      var videobackgroundDiv = document.querySelector(".video-background");

      fetch(`https://api-dem.vercel.app/api/${this.selectedPullType}`)
        .then((response) => response.json())
        .then((data) => {
          const {
            card_type,
            prize,
            won_amount,
            currencySymbol,
            additional_value,
          } = data;
          const legendaryPrizeToImage = {
            ...prizeToImage,
            "Free Spins": "/assets/02_questionmark.png", // calea către noua imagine
          };
        
          const currentPrizeToImage =
            card_type === "Legendary" ? legendaryPrizeToImage : prizeToImage;
            
          if (card_type === "Legendary") {
            self.isLegendary=true
            if(this.isAnimationPlaying) {
              console.log("Legendary card aniamtion",self.isAnimationPlaying);
              //on click stop the animation
              
            }
            
            function handleInteraction() {
              console.log('interaction');
              if (self.isLegendary) {
                  console.log('isLegendary interaction');
                  document.body.classList.add('skip-animation');
                  particleImplosion.destroy(); // Distruge obiectul după animație
                  particleExplosion.destroy(); // Distruge obiectul după animație
                  // fireworks.destroy(); // Distruge obiectul după animație
                 
                  self.sound.stopAll();


              }
          }
          
          // Adaugă evenimentele pentru click și touchstart
          document.addEventListener('click', handleInteraction);
          document.addEventListener('touchstart', handleInteraction);
            console.log("Legendary card, isLegendary is true.");  
            videobackground.play(); 
 
            setTimeout(() => {
           
             
                // this.sound.play("boom", { volume: 1 })
                if (!document.body.classList.contains('skip-animation')) {
                  this.sound.play("boom", { volume: 1 });
              }
            
            }, 4900)
         
            setTimeout(() => {
              particleExplosion.setVisible(true);
              self.playAnimation(particleExplosion, "animation", false).then(() => {

                particleExplosion.setVisible(false); // Ascunde animația după redare
              });
            
            }, 5900); // Așteaptă 4 secunde înainte de a ascunde div-ul
            setTimeout(() => {
              videobackgroundDiv.remove(); // Ascunde div-ul după ce animația este completă
            }, 6300); 
            
         
            setTimeout(() => {
              particleImplosion.setVisible(true);
              self.playAnimation(particleImplosion, "animation", true); // Loop animation
            }, 1000); // Delay de 1000 ms înainte de a porni animația Spine
          
            

            setTimeout(() => {
              document.querySelector(".container").style.zIndex = "1"; // Schimbă z-index la 1
              const isAndroid = document.body.classList.contains('android');
              
              let yTarget;
              if (isAndroid) {
                yTarget = particleImplosion.y - 1200; // Adaugă condiția specifică pentru Android cu max-width 420px
              } else if (window.innerWidth < 600) {
                yTarget = particleImplosion.y - 1100; // Condiție pentru mobil

              }else if (globalDeviceInfo.smallerSide == 834 && !isAndroid) {
                yTarget = particleImplosion.y - 370; // Condiție pentru mobil
                
              }
              else if (window.innerWidth <= 1920) {
                yTarget = particleImplosion.y - 120; // Condiție pentru rezoluții până la 1920px
              } else if (window.innerWidth <= 2560) {
                yTarget = particleImplosion.y - 100; // Condiție pentru rezoluții până la 2k
              } else if (window.innerWidth <= 3440) {
                yTarget = particleImplosion.y - 150; // Condiție pentru rezoluții până la 4k
              }else if (window.innerWidth <= 3840) {
                yTarget = particleImplosion.y - 260; // Condiție pentru rezoluții până la 4k
              }  else if (window.innerWidth <= 5120) {
                yTarget = particleImplosion.y - 300; // Condiție pentru rezoluții până la 5k
              } else {
                yTarget = particleImplosion.y - 180; // Condiție implicită
              }
              console.log("yTarget", yTarget);
              self.tweens.add({
                targets: particleImplosion,
                y: yTarget, // Valoarea până la care va urca
                duration: 5000, // Durata animației (5 secunde)
                ease: 'Linear',
                onComplete: () => {
                  particleImplosion.destroy(); // Distruge obiectul după animație
                }
              });
            }, 1000);
            
            document.querySelector(".card-footer").style.display = "none";
            document.querySelector(".animationcard").style.display = "block";
            // document.querySelector(".video-background video").style.display =
            //   "block";

            // setTimeout(() => {
            //   document.querySelector(".explosion").style.visibility = "visible";
            //   document.querySelector(".video-background video").style.display = "none";
            // }, 6000);
          }
          // var video = document.querySelector(".explosion video");

          // // Verifică dacă videoclipul a început să ruleze
          // video.onplaying = function () {
          //   // Opriți videoclipul după un anumit timp, de exemplu 10 secunde
          //   setTimeout(function () {
          //     document.querySelector(".explosion").remove();
          //     video.pause(); // Oprește videoclipul
          //     video.currentTime = 0; // Opțional, resetează timpul la început
          //   }, 1000); // 10000 milisecunde = 10 secunde
          // };
          // Ascunde div-ul după ce animația este completă
          const cardtwo = document.querySelector(".card"); // Selectează elementul card
          cardtwo.classList.remove("object", "new3"); // Îndepărtează clasele existente pentru a evita conflictele

          if (card_type === "Legendary") {
            cardtwo.classList.add("new3"); // Adaugă clasa `new3` pentru animația specială
            console.log("Legendary card, applying new3 animation.");
            // this.sound.play("legendary", { volume: 1 })
           
          } else {
            cardtwo.classList.add("object"); // Aplică clasa `object` pentru restul cardurilor
          }
          const cardwins = document.querySelector(".cardwins");
          cardwins.classList.remove("hide");
          const additionalValueText =
            document.querySelector(".additional-value");
          // additionalValueText.textContent = additional_value;
          const cardBottom = document.querySelector(".cardbottom");
          // cardBottom.classList.add("animate__headShake");
          additionalValueText.textContent = "0"; // începe de la 0
        
          if (card_type === "Legendary") {
            let checkSkipAnimationInterval;
            const startAnimation = () => {
                animateValue(additionalValueText, 0, additional_value, 1000);
            };
        
            if (document.body.classList.contains('skip-animation')) {
                console.log("Skip animation class is present, setting value quickly.");
                animateValue(additionalValueText, 0, additional_value, 1);
            } else {
                let elapsed = 0;
                checkSkipAnimationInterval = setInterval(() => {
                    if (document.body.classList.contains('skip-animation')) {
                        clearInterval(checkSkipAnimationInterval);
                        animateValue(additionalValueText, 0, additional_value, 1);
                    } else {
                        elapsed += 100;
                        if (elapsed >= 7200) {
                            clearInterval(checkSkipAnimationInterval);
                            startAnimation();
                        }
                    }
                }, 100);
            }
        } else {
            if (document.body.classList.contains('skip-animation')) {
                animateValue(additionalValueText, 0, additional_value, 1);
            } else {
                animateValue(additionalValueText, 0, additional_value, 1000);
            }
        }  
        const existingLeiDiv = document.querySelector(".lei-div");
  if (existingLeiDiv) {
    existingLeiDiv.remove();
  }
        if (prize === "Bonus Funds" || prize === "Free Bet") {
          const leiDiv = document.createElement("div");
          leiDiv.className = "lei-div"; // Adaugă o clasă pentru stilizare
          leiDiv.textContent = "Lei";
          
          // Adaugă div-ul "Lei" lângă additional-value
          additionalValueText.parentNode.insertBefore(leiDiv, additionalValueText.nextSibling);
        }
        // if (prize === "Bonus Funds" || prize === "Free Bet") {
        //   console.log("Bonus Funds or Free Bet prize.");
        //   additionalValueText.textContent += " Lei";
        // }

          // if (card_type === "Legendary") {
          //   //delay function to wait for the animation to finish
          //   setTimeout(() => {
          //     animateValue(additionalValueText, 0, additional_value, 1000);
          //   }, 7200);
          // } else {
          //   animateValue(additionalValueText, 0, additional_value, 1000);
          // }



          
          const cardTypeElement = document.querySelector(".cardType");

          const cardContainerepic = cardTypeElement.parentNode; // Preia părintele div-ului .cardType
          const existingContent = cardContainerepic.querySelector(
            ".additional-content"
          );

          if (existingContent) {
            cardContainerepic.removeChild(existingContent);
            cardTypeElement.style.background = "";

            cardTypeElement.style.border = ""; // Elimină border-ul
          }
          cardTypeElement.textContent = card_type;
          const newContentContainer = document.createElement("div");
          newContentContainer.className = "additional-content"; // Adaugă o clasă pentru stilizare
          newContentContainer.classList.add("slide-in-right");
          if (card_type === "Epic" && prize === "Multiplier") {
            cardTypeElement.style.background = "#A22CDA";
            cardTypeElement.style.border = "2px solid #5E00B6"; // Setează border-ul

            const image = document.createElement("img");
            image.src = "/assets/racheta2.png"; // Asigură-te că calea este corectă
            image.alt = "Multiplied";

            const additionalDiv = document.createElement("div");
            additionalDiv.textContent = "Multiplied"; // Text pentru div-ul suplimentar

            // Adaugă imaginea și div-ul în containerul nou
            newContentContainer.appendChild(image);
            newContentContainer.appendChild(additionalDiv);

            // Adaugă containerul nou în containerul părinte, după cardTypeElement
            cardContainerepic.appendChild(newContentContainer);
          }
          cardTypeElement.className = `cardType ${card_type.toLowerCase()}`; // Update class to reflect the type
          void cardTypeElement.offsetWidth; // Force reflow

          if (card_type === "Legendary") {
            cardTypeElement.classList.add("slide-in-right-delayed");
          } else {
            cardTypeElement.classList.add("slide-in-right");
          }
          // Mapping of prize types to display texts
          const detailsMap = {
            Cash: "Your balance",
            "Bonus Funds": "Your balance",
            "Free Spins": "Your free spins",
            "Free Bets": "Total Free Bet",
            "Golden Chips": "Total Golden Chips",
            "Loyalty Points": "Your Loyalty Points",
            Multiplier: "All wins in this chest are multiplied x5",
            "Shop Points": "Your Shop Points",
            "Wheel Spins": "Spin the wheel multiple times!",
            FunCoins: "Total FunCoins",
          };
          // Image map for specific card types
          const cardImageMap = {
            "Free Bets": "/assets/prize-icons/free-bets.png",
            "Free Spins": "/assets/prize-icons/free-spins.png",
            "Golden Chips": "/assets/prize-icons/golden-chips.png",
            "Loyalty Points": "/assets/prize-icons/loyalty-points.png",
            "Shop Points": "/assets/prize-icons/shop-points.png",
            FunCoins: "/assets/prize-icons/win-coins.png",
          };

          const cardDetailsElement = document.querySelector(".cardDetails");
          cardDetailsElement.textContent = detailsMap[prize] || "Prize Details";
          if (prize === "Multiplier") {
            console.log("Multiplier");
            cardDetailsElement.classList.add("Multiplier");
          }

          cardDetailsElement.classList.remove("slide-in-right");
          void cardDetailsElement.offsetWidth; // Force reflow

          if (card_type === "Legendary") {
            cardDetailsElement.classList.add("slide-in-right-delayed");
          } else {
            cardDetailsElement.classList.add("slide-in-right");
            cardDetailsElement.classList.add("slide-in-right");
          }
          const cardWinsImgElement = document.querySelector(".cardWinsImg");
          if (cardImageMap[prize]) {
            cardWinsImgElement.src = cardImageMap[prize];
            cardWinsImgElement.style.display = "";
          } else {
            cardWinsImgElement.style.display = "none";
          }

          // Special handling for Multiplier and Wheel Spins to hide the cardwins
          const cardWinsElement = document.querySelector(".cardwins");
          if (prize === "Multiplier" || prize === "Wheel Spins") {
            cardWinsElement.style.display = "none"; // Hide card wins
          } else {
            cardWinsElement.style.display = ""; // Default display
            cardWinsElement.classList.remove("slide-in-right");
            void cardWinsElement.offsetWidth; // Force reflow
            // cardWinsElement.classList.add("slide-in-right");
            if (card_type === "Legendary") {
              cardWinsElement.classList.add("slide-in-right-delayed");
            } else {
              cardWinsElement.classList.add("slide-in-right");
            }
            if (card_type === "Legendary") {
              setTimeout(() => {
                // this.sound.play("cash", { volume: 0.3 });

                // cardWinsElement.classList.add("animate__headShake");
              }, 7200);
            } else {
              setTimeout(() => {
                // this.sound.play("cash", { volume: 0.3 });

                // cardWinsElement.classList.add("animate__headShake");
              }, 500);
            }
          }
          // if (cardWinsElement.style.display !== 'none') {
          //     cardWinsElement.classList.remove('slide-in-right');
          //     void cardWinsElement.offsetWidth; // Force reflow
          //     cardWinsElement.classList.add('slide-in-right');
          //   }

          let imagePath = "/assets/noi/default.png";
          let backimagePath = "/assets/noi/default.png";
          const iconImagePath =
            currentPrizeToImage[prize] || "/assets/prizes/default-icon.png";

          switch (card_type) {
            case "Common":
              imagePath = "/assets/cards-bg/card_common.png";
              backimagePath = "/assets/card-back/cardback_common.png";
              break;
            case "Uncommon":
              imagePath = "/assets/cards-bg/card_uncommon.png";
              backimagePath = "/assets/card-back/cardback_uncommon.png";
              break;
            case "Rare":
              imagePath = "/assets/cards-bg/card_rare.png";
              backimagePath = "/assets/card-back/cardback_rare.png";
              break;
            case "Epic":
              imagePath = "/assets/cards-bg/card_epic.png";
              backimagePath = "/assets/card-back/cardback_epic.png";
              break;
            case "Legendary":
              // imagePath = "/assets/cards-bg/card_legendary.png";
              imagePath = "/assets/card-back/cardback_legendary.png";
              backimagePath = "/assets/card-back/cardback_legendary.png";
              break;
          }

          const cardFrontImage = document.querySelector(".card .front img");
          cardFrontImage.src = imagePath;
          const cardFrontIcon = document.querySelector(
            ".card .front .front-icon"
          );
          if (cardFrontIcon) {
            cardFrontIcon.src = iconImagePath;
          }
          const cardBackImage = document.querySelector(".card .back img");
          cardBackImage.src = backimagePath;
          cardBackImage.style.filter = "brightness(0.5) invert(1)"; // Poți ajusta acest stil după preferințe

          // Aplică filtrul none după 250 ms pentru fiecare clic
          setTimeout(() => {
            cardBackImage.style.filter = "none";
          }, 250);

          const prizeTextElement = document.querySelector(".textPrize");
          prizeTextElement.textContent = `${prize}`;
          if (this.selectedPullType === "multiplier" && prize !== "Multiplier") {
            const multiplierSpan = document.createElement("span");
            multiplierSpan.textContent = " x5"; // Include spațiu înainte de "X5" pentru separare
            
            // Condiție pentru mobil și premiul "Loyalty Points"
            const isMobile = window.innerWidth < 800;
            if (isMobile && prize === "Loyalty Points") {
              prizeTextElement.style.fontSize = "30px"; // Setează dimensiunea fontului pentru mobil
              multiplierSpan.className = "multiplier-class-small"; // Adaugă clasa multiplier-class-small pentru mobil și "Loyalty Points"
            } else {
              multiplierSpan.className = "multiplier-class"; // Adaugă clasa multiplier-class în celelalte cazuri
            }
            
            prizeTextElement.appendChild(multiplierSpan);
          }
          
          if (won_amount.length > 10) {
            const cardFooter = document.querySelector(".card-footer");

            cardFooter.style.fontSizAdjust = "0.4"; // Asigurați-vă că browserul suportă această proprietate
          }
          const amountTextElement = document.querySelector(".card .card-txt");
          amountTextElement.textContent = `${won_amount} `;
   

          this.openedCards.push({
            imagePath,
            iconImagePath,
            prize,
            won_amount,
            currencySymbol,
          });
          if (prize === "Multiplier") {
            this.hasMultiplier = true;
          }
          if (card_type === "Legendary") {
            console.log("Legendary");
            this.sound.play("legendary", { volume: 0.5 });
          }else {
            this.sound.play("get_card", { volume: 0.5 });

          }

          this.cardIndex = (this.cardIndex + 1) % cardImages.length;
          self.playAnimation(lootbox, "open2", false).then(() => {
            self.isAnimationPlaying = false;

            self.playAnimation(lootbox, "idle_open", true);
          });
          
          const cardContainer = document.querySelector(".container");
          const lootContainer = document.querySelector(".loot");
          const card = document.querySelector(".card");
          const staticCards = document.querySelector(".chest-cards");
          const textCongrats = document.querySelector(".textPrize");
          const roundsElement = document.querySelector(".rounds");
          const chestCards = document.querySelector(".chest-cards");
          let currentValue = parseInt(roundsElement.textContent, 10);
          //   staticCards.classList.add("heartbeat");
          const temporarypng = document.querySelector(".temporarypng");
          if (!isNaN(currentValue) && currentValue > 0) {
            currentValue -= 1;
            roundsElement.textContent = currentValue;
          }
          if (currentValue === 0) {
            this.gameOver = true; // Marchează sfârșitul jocului
            const tapText = document.querySelector(".tapText");
            const claimButton = document.querySelector(".claimButton");
            tapText.style.display = "none";
            let timeoutDuration = 3500; // durata implicită
            if (this.selectedPullType === "legendary") {
              timeoutDuration = 10000; // durata extinsă pentru legendary
            }
            setTimeout(() => {
              cardContainer.classList.add("hide");
              claimButton.classList.remove("hide");
              const canvasElement = document.querySelector(
                "#anime-container canvas"
              );
            this.card1.setVisible(false);
            this.card2.setVisible(false);
            this.roundsText.setVisible(false);
              // if (canvasElement) {
              //   if (this.hasMultiplier) {
              //     console.log("Multiplier prize");
                  
              //     if (window.innerWidth > 1600) {
              //       canvasElement.classList.add("anime-slide-up-big");
              //     }else {
              //       canvasElement.classList.add("anime-slide-up");

              //     }
              //   } else {
                  
              //     if (window.innerWidth > 1600) {
              //       canvasElement.classList.add("anime-slide-up-normal-box-big");
              //     }else {
              //       canvasElement.classList.add("anime-slide-up-normal-box");

              //     }
                  
              //   }
              // }
              // if (canvasElement) {
              //   if (this.hasMultiplier) {
              //     console.log("Multiplier prize");
              
              //     if (window.innerWidth < 800) {
              //       self.tweens.add({
              //         targets: lootbox,
              //         y: lootbox.y - 1550,
              //         x: lootbox.x, // Muta la stanga cu 40 pixeli
              //         scale: 1,
              //         duration: 300,
              //         ease: 'Linear'
              //       });
              //     } else {
              //       self.tweens.add({
              //         targets: lootbox,
              //         y: lootbox.y - 450,
              //         x: lootbox.x - 30, // Muta la stanga cu 40 pixeli
              //         scale: 0.4,
              //         duration: 300,
              //         ease: 'Linear'
              //       });
              //     }
              //   } else {
              //     // Dacă nu este Multiplier, aplica direct animația pentru altă condiție
              //     if (window.innerWidth < 800) {
              //       self.tweens.add({
              //         targets: lootbox,
              //         y: lootbox.y - 1300,
              //         x: lootbox.x , // Muta la stanga cu 40 pixeli
              //         scale: 1,
              //         duration: 300,
              //         ease: 'Linear'
              //       });
              //     } else {
              //       self.tweens.add({
              //         targets: lootbox,
              //         y: lootbox.y - 400,
              //         x: lootbox.x - 20, // Muta la stanga cu 40 pixeli
              //         duration: 500,
              //         ease: 'Linear'
              //       });
              //     }
              //   }
              // }
              
              if (canvasElement) {
                if (this.hasMultiplier) {
                    console.log("Multiplier prize");
            
                    if (globalDeviceInfo.smallerSide === 834) {
                        self.tweens.add({
                            targets: lootbox,
                            y: lootbox.y - 1100,
                            x: lootbox.x - 20, // Muta la stanga cu 20 pixeli
                            scale: 1,
                            duration: 300,
                            ease: 'Linear'
                        });
                    } else if (window.innerWidth < 800) {
                        self.tweens.add({
                            targets: lootbox,
                            y: lootbox.y - 2900,
                            x: lootbox.x, // Muta la stanga cu 40 pixeli
                            scale: 1.5,
                            duration: 300,
                            ease: 'Linear'
                        });
                    } else if (window.innerWidth <= 1920) {
                        self.tweens.add({
                            targets: lootbox,
                            y: lootbox.y - 550,
                            x: lootbox.x - 30, // Muta la stanga cu 40 pixeli
                            scale: 0.4,
                            duration: 300,
                            ease: 'Linear'
                        });
                    } else {
                        self.tweens.add({
                            targets: lootbox,
                            y: lootbox.y - 450,
                            x: lootbox.x - 30, // Muta la stanga cu 40 pixeli
                            scale: 0.4,
                            duration: 300,
                            ease: 'Linear'
                        });
                    }
                } else {
                    // Dacă nu este Multiplier, aplica direct animația pentru altă condiție
                    if (globalDeviceInfo.smallerSide === 834) {
                        self.tweens.add({
                            targets: lootbox,
                            y: lootbox.y - 950,
                            x: lootbox.x - 10, // Muta la stanga cu 10 pixeli
                            scale: 1,
                            duration: 500,
                            ease: 'Linear'
                        });
                    } else if (window.innerWidth < 800) {
                        self.tweens.add({
                            targets: lootbox,
                            y: lootbox.y - 2000,
                            x: lootbox.x, // Muta la stanga cu 40 pixeli
                            scale: 2,
                            duration: 300,
                            ease: 'Linear'
                        });
                    } else if (window.innerWidth <= 1920) {
                        self.tweens.add({
                            targets: lootbox,
                            y: lootbox.y - 500,
                            x: lootbox.x - 10, // Muta la stanga cu 40 pixeli
                            duration: 500,
                            ease: 'Linear'
                        });
                    } else {
                        self.tweens.add({
                            targets: lootbox,
                            y: lootbox.y - 400,
                            x: lootbox.x - 20, // Muta la stanga cu 40 pixeli
                            duration: 500,
                            ease: 'Linear'
                        });
                    }
                }
            }
            
              
              this.displayAllCards();
              premium.setVisible(false);

            }, timeoutDuration);
          }

          card.style.animation = "none";
          card.offsetHeight;
          card.style.animation = "";
          card.style.opacity = "1";
          card.style.visibility = "visible";
          staticCards.style.opacity = "1";
          card.classList.add("object");
          staticCards.classList.add("scale-in-center");
          let isShineEffectAdded = false;
          const isAndroid = document.body.classList.contains('android');

          function addAndRemoveShineEffect(gameObject) {
            // Adaugă efectul de shine
            gameObject.preFX.addShine(1, 12, 5, false);
          
            // Elimină efectul de shine după 200ms
            setTimeout(() => {
              gameObject.preFX.clear();
            }, 200);
          }
          if (globalDeviceInfo.smallerSide === 834) {
            if (this.isFirstClick) {
                // Animație specifică pentru primul click pe dispozitive cu globalDeviceInfo.smallerSide egal cu 834
                this.tweens.add({
                    targets: [this.card1, this.card2],
                    scale: { from: 0, to: 2},
                    alpha: { from: 1, to: 1 },
                    ease: 'Cubic.easeOut',
                    duration: 500,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                        this.isFirstClick = false;
                    }
                });
        
                this.tweens.add({
                    targets: this.roundsText,
                    scale: { from: 0, to: 1 },
                    alpha: { from: 0, to: 1 },
                    ease: 'Cubic.easeOut',
                    duration: 500,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
                addAndRemoveShineEffect(this.card2);
            } else {
                // Animație specifică pentru click-urile ulterioare pe dispozitive cu globalDeviceInfo.smallerSide egal cu 834
                this.tweens.add({
                    targets: [this.card1, this.card2],
                    scale: { from: 2, to: 3 },
                    yoyo: true,
                    repeat: 0,
                    ease: 'Sine.easeInOut',
                    duration: 250,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
        
                this.tweens.add({
                    targets: [this.card1, this.card2],
                    alpha: { from: 1, to: 1 },
                    yoyo: true,
                    repeat: 1,
                    duration: 100,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
        
                this.tweens.add({
                    targets: this.roundsText,
                    scale: { from: 1, to: 2 },
                    yoyo: true,
                    repeat: 0,
                    duration: 250,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
                addAndRemoveShineEffect(this.card2);
            }
        } else if (window.innerWidth < 1024 || isAndroid) {
            if (this.isFirstClick) {
                // Prima animație la primul click pentru rezoluții mai mici de 1024
                this.tweens.add({
                    targets: [this.card1, this.card2],
                    scale: { from: 0, to: 8.5 },
                    alpha: { from: 1, to: 1 },
                    ease: 'Cubic.easeOut',
                    duration: 500,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                        this.isFirstClick = false;
                    }
                });
        
                this.tweens.add({
                    targets: this.roundsText,
                    scale: { from: 0, to: 2.9 },
                    alpha: { from: 0, to: 1 },
                    ease: 'Cubic.easeOut',
                    duration: 500,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
                addAndRemoveShineEffect(this.card2);
            } else {
                // Animația pentru click-urile ulterioare pentru rezoluții mai mici de 1024
                this.tweens.add({
                    targets: [this.card1, this.card2],
                    scale: { from: 8.5, to: 9.5 },
                    yoyo: true,
                    repeat: 0,
                    ease: 'Sine.easeInOut',
                    duration: 250,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
        
                this.tweens.add({
                    targets: [this.card1, this.card2],
                    alpha: { from: 1, to: 1 },
                    yoyo: true,
                    repeat: 1,
                    duration: 100,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
        
                this.tweens.add({
                    targets: this.roundsText,
                    scale: { from: 2.9, to: 3.9 },
                    yoyo: true,
                    repeat: 0,
                    duration: 250,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
                addAndRemoveShineEffect(this.card2);
            }
        } else {
            if (this.isFirstClick) {
                // Prima animație la primul click pentru rezoluții de 1024 și mai mari
                this.tweens.add({
                    targets: [this.card1, this.card2],
                    scale: { from: 0, to: 1.1 },
                    alpha: { from: 0, to: 1 },
                    ease: 'Cubic.easeOut',
                    duration: 500,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                        this.isFirstClick = false;
                    }
                });
        
                this.tweens.add({
                    targets: this.roundsText,
                    scale: { from: 0, to: 0.8 },
                    alpha: { from: 0, to: 1 },
                    ease: 'Cubic.easeOut',
                    duration: 500,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
                addAndRemoveShineEffect(this.card2);
            } else {
                // Animația pentru click-urile ulterioare pentru rezoluții de 1024 și mai mari
                this.tweens.add({
                    targets: [this.card1, this.card2],
                    scale: { from: 1, to: 1.5 },
                    yoyo: true,
                    repeat: 0,
                    ease: 'Sine.easeInOut',
                    duration: 250,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
        
                this.tweens.add({
                    targets: this.roundsText,
                    scale: { from: 0.8, to: 1 },
                    yoyo: true,
                    repeat: 0,
                    duration: 250,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
                addAndRemoveShineEffect(this.card2);
            }
        }
        
          
          
        
          this.updateRoundsText(); // Actualizează textul pe scenă
          chestCards.classList.remove("pulsate-fwd");
          void chestCards.offsetWidth; // Reflow: citirea acestei proprietăți forțează reflow-ul
          chestCards.classList.add("pulsate-fwd");
      
          // Verifică condițiile pentru aplicarea clasei 'pulsate-fwd-legendary'
          if (this.selectedPullType === "legendary" && this.numRounds === 1) {
            if (globalDeviceInfo.smallerSide === 834) {
                // Animații specifice pentru dispozitive cu globalDeviceInfo.smallerSide egal cu 834
                this.tweens.add({
                    targets: [this.card1, this.card2],
                    scale: { from: 2, to: 3 },
                    yoyo: true,
                    repeat: -1,
                    ease: 'Sine.easeInOut',
                    duration: 100,
                    onUpdate: (tween, target) => {
                        if (target.scale > 7.5) {
                            if (!target.isShining) {
                                target.preFX.addShine(1, 12, 5, false);
                                target.isShining = true;
                            }
                        } else {
                            if (target.isShining) {
                                target.preFX.clear();
                                target.isShining = false;
                            }
                        }
                    },
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
        
                this.tweens.add({
                    targets: this.roundsText,
                    scale: { from: 1, to: 2
                     },
                    yoyo: true,
                    repeat: -1,
                    duration: 100,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
        
            } else if (window.innerWidth < 1024 || isAndroid) {
                this.tweens.add({
                    targets: [this.card1, this.card2],
                    scale: { from: 8.5, to: 9.5 },
                    yoyo: true,
                    repeat: -1,
                    ease: 'Sine.easeInOut',
                    duration: 100,
                    onUpdate: (tween, target) => {
                        if (target.scale > 9) {
                            if (!target.isShining) {
                                target.preFX.addShine(1, 12, 5, false);
                                target.isShining = true;
                            }
                        } else {
                            if (target.isShining) {
                                target.preFX.clear();
                                target.isShining = false;
                            }
                        }
                    },
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    } 
                });
        
                this.tweens.add({
                    targets: this.roundsText,
                    scale: { from: 2.9, to: 3.9 },
                    yoyo: true,
                    repeat: -1,
                    duration: 100,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
        
            } else {
                this.tweens.add({
                    targets: [this.card1, this.card2],
                    scale: { from: 1, to: 1.5 },
                    yoyo: true,
                    repeat: -1,
                    ease: 'Sine.easeInOut',
                    duration: 100,
                    onUpdate: (tween, target) => {
                        if (target.scale > 1.25) {
                            if (!target.isShining) {
                                target.preFX.addShine(1, 12, 5, false);
                                target.isShining = true;
                            }
                        } else {
                            if (target.isShining) {
                                target.preFX.clear();
                                target.isShining = false;
                            }
                        }
                    },
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
        
                this.tweens.add({
                    targets: this.roundsText,
                    scale: { from: 0.8, to: 1 },
                    yoyo: true,
                    repeat: -1,
                    duration: 100,
                    onComplete: () => {
                        this.isAnimationPlaying = false;
                    }
                });
            }
        
        } else if (this.selectedPullType === "legendary" && this.numRounds === 0) {
            if (globalDeviceInfo.smallerSide === 834) {
                // Resetează animațiile pentru dispozitive cu globalDeviceInfo.smallerSide egal cu 834
                this.tweens.killTweensOf([this.card1, this.card2, this.roundsText]);
                this.card1.clearTint();
                this.card2.clearTint();
                this.roundsText.setScale(1);
                this.card1.setScale(2);
                this.card2.setScale(2);
        
            } else if (window.innerWidth < 1024 || isAndroid) {
                this.tweens.killTweensOf([this.card1, this.card2, this.roundsText]);
                this.card1.clearTint();
                this.card2.clearTint();
                this.roundsText.setScale(2.9);
                this.card1.setScale(8.5);
                this.card2.setScale(8.5);
        
            } else {
                this.tweens.killTweensOf([this.card1, this.card2, this.roundsText]);
                this.card1.clearTint();
                this.card2.clearTint();
                this.roundsText.setScale(0.8);
                this.card1.setScale(1);
                this.card2.setScale(1);
            }
        }
        
      // //if selectedpulltype is multipler add class to multiplyerclass2
      // if (this.selectedPullType === "multiplier") {
      //   multiplierText2.classList.remove("fade-inx5");
      //     void multiplierText2.offsetWidth; // Reflow: citirea acestei proprietăți forțează reflow-ul
      //     setTimeout(() => {
      //       multiplierText2.classList.add("fade-inx5");
      //     }, 800);
      // } 

  // Forțează reflow-ul pentru a permite redeclanșarea animației
  void chestCards.offsetWidth;
          textCongrats.style.animation = "none";
          textCongrats.offsetHeight;
          textCongrats.style.animation = "";
          textCongrats.classList.remove("slide-in-right");
          void textCongrats.offsetWidth;

          if (card_type === "Legendary") {
            textCongrats.classList.add("slide-in-right-delayed");
          } else {
            textCongrats.classList.add("slide-in-right");
          }

          card.addEventListener("animationend", function handler2(e) {
            
            if (e.animationName === "new-animation22") {
              
              card.classList.remove("new3");
              card.classList.add("reset-transform"); // O clasă care resetează transformările

              card.classList.add("jello-horizontal");
              // temporarypng.remove();
  
              document.querySelector(".card-footer").style.display = "block";
              const cardFrontImage = document.querySelector(".card .front img");
              cardFrontImage.src = "/assets/cards-bg/card_legendary.png";
              cardFrontImage.style.filter = "brightness(0.5) invert(1)"; // Poți ajusta acest stil după preferințe

              // Aplică filtrul none după 250 ms pentru fiecare clic
              setTimeout(() => {
                cardFrontImage.style.filter = "none";
              }, 500);
              const cardFrontIcon = document.querySelector(
                ".card .front .front-icon"
              );
              cardFrontIcon.src = "/assets/cards-icons/free_spins.png";
              card.removeEventListener("animationend", handler2);
              if (card_type === "Legendary") {
                textCongrats.classList.add("slide-in-right-delayed");
              } else {
                textCongrats.classList.add("slide-in-right");
              }
              // textCongrats.classList.add("slide-in-right");
            }
          });
          card.addEventListener("animationend", function handler3(e) {
            if (e.animationName === "jello-horizontal" && !self.isSvgAdded) {

              // Verifică dacă animația care s-a încheiat este cea dorită
              // card.classList.remove("jello-horizontal");  // Oprește animația curentă
              card.classList.add("slide-left"); // Adaugă noua animație
              // const animationCard = document.querySelector(".animationcard");
              // const svgObject = document.createElement("object");
              // svgObject.type = "image/svg+xml";
              // svgObject.data = "/assets/card-animation-2.svg";
              // svgObject.classList.add("svg-anim");
              // animationCard.appendChild(svgObject);
              // self.isSvgAdded = true; // Marchează SVG-ul ca fiind adăugat

              card.removeEventListener("animationend", handler3); // Elimină listener-ul pentru a preveni repetiții
            }
            card.addEventListener("animationend", function handler4(e) {
              if (e.animationName === "slide-left") {
                // Verifică dacă animația care s-a încheiat este cea dorită
                premium.setVisible(true);
                self.playAnimation(premium, "particles4", true); // Loop animation

                card.removeEventListener("animationend", handler4); // Elimină listener-ul pentru a preveni repetiții
              }
            });
          });
        })
        .catch((error) => {
          console.error("Error fetching card data:", error);
          this.isAnimationPlaying = false;
        });
    });

    this.startAnimationSequence(lootbox);
    this.chestCardsDiv = document.querySelector(".chest-cards");

    

  }

  update() {
    if (this.lootbox) {
      // Actualizează poziția div-ului pe baza poziției animației lootbox
      console.log("Lootbox position:", this.lootbox.x, this.lootbox.y);
      const x = this.lootbox.x;
      const y = this.lootbox.y;
  
      this.chestCardsDiv.style.left = `${x}px`;
      this.chestCardsDiv.style.top = `${y}px`;
    }
    this.fpsText.setText('FPS: ' + Math.round(this.game.loop.actualFps));

  }
  resizeDiscoFX(discoFX) {
    // discoFX.setPosition(this.cameras.main.width / 2, this.cameras.main.height / 2);
    // discoFX.setScale(this.cameras.main.width / discoFX.width, this.cameras.main.height / discoFX.height);
  }

  playDiscoFX(discoFX) {
    // this.resizeDiscoFX(discoFX); // Resize and reposition to full screen
    discoFX.setVisible(true);
    // this.playAnimation(discoFX, "animation", false).then(() => {
    //   discoFX.setVisible(false); // Hide after animation completes
    // });
  }

  updateRoundsText() {
    if (this.roundsText) {
      this.roundsText.setText(this.numRounds.toString());
    }
  } 
  displayAllCards() {
    const lootDiv = document.querySelector(".loot");
    const calinBtn = document.querySelector(".claimButton");
    lootDiv.innerHTML = ""; // Curăță tot conținutul div-ului loot

    // Crează și adaugă header-ul
    const lootHeader = document.createElement("div");
    lootHeader.className = "lootheader";
    lootHeader.innerHTML = `
      <div class="titleLoot">Your loot</div>
      <div id="helpButton" class="help" ><span class="question"><img src="/assets/questionm_02.png" class="smallquestion"> </span></div>
    `;
    lootDiv.appendChild(lootHeader);
    const popUp = document.createElement('div');
    popUp.className = 'pop-up';
    popUp.innerHTML = `
      <div class="pop-up-content">
        <h2>Help</h2>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
        <button id="closePopUp">Close</button>
      </div>
    `;
    document.body.appendChild(popUp);
    
    // Adaugă event listener pentru butonul de ajutor
    const helpButton = document.getElementById('helpButton');
    const popContent = document.querySelector('.pop-up-content');
    if (helpButton) {
      helpButton.addEventListener('click', (event) => {
          event.stopPropagation(); // Oprește propagarea evenimentului
          console.log('Help button clicked');
          popUp.style.display = 'flex';
          popContent.classList.add('slide-in-elliptic-bottom-fwd');
          
      });
  } else {
      console.error('Help button not found');
  }
    
    // Adaugă event listener pentru butonul de închidere
    const closeButton = document.getElementById('closePopUp');
    closeButton.addEventListener('click', () => {
      popUp.style.display = 'none';
    });
    
    // Închide pop-up-ul când se face clic în afara conținutului
    popUp.addEventListener('click', (event) => {
      if (event.target === popUp) {
        popUp.style.display = 'none';
      }
    });
    // Crează un nou div pentru a grupa multiplier-loot și all-cards-display
    const middleDiv = document.createElement("div");
    middleDiv.className = "middle";

    // Verifică dacă există un premiu de tip Multiplier și adaugă div-ul specific
    const hasMultiplier = this.openedCards.some(
      (card) => card.prize === "Multiplier"
    );
    if (hasMultiplier) {
      const multiplierDiv = document.createElement("div");
      multiplierDiv.className = "multiplier-loot";
      multiplierDiv.innerHTML = `
        <div class="multiplier-img">
        <div class="card-down">
            <img src="/assets/cards-bg/card_epic.png" class="card-image-multiplier" style="width: 90px!important;">
            <img src="/assets/cards-icons/multiplier.png" class="card-icon" style="width: 90px!important;">
            <div class="card-details-multiplied">
              x5 
            </div>
          </div>
        </div>
        <div class="multiplier-text">
          <div class="multiplier-title">Multiplier</div>
          <div class="multiplier-type">Epic</div>
          <div class="multiplier-value">All wins are multiplied x5</div>
        </div>
      `;
      middleDiv.appendChild(multiplierDiv);
    }

    // Crează containerul pentru carduri
    const cardsDisplay = document.createElement("div");
    cardsDisplay.className = "all-cards-display card-container";
    middleDiv.appendChild(cardsDisplay);

    // Populează containerul de carduri cu carduri din `openedCards`
    // Dacă tipul selectat este 'multiplier', afișează toate cardurile, cu excepția primei cărți
    const cardsToDisplay = this.selectedPullType === 'multiplier' ? this.openedCards.slice(1) : this.openedCards;

    cardsToDisplay.forEach((card,index) => {
      const cardElement = document.createElement("div");
      let additionalClass = card.won_amount === "1000" ? "glow-premium" : "";
      let cardImagePath = card.imagePath;
      cardElement.className = `card-down ${additionalClass}`;
      const cardDetailsClass =
        card.won_amount.length > 9
          ? "card-details summary long-text"
          : "card-details summary";
      // Verifică dacă tipul de card este 'Legendary' și premiul este 'Free Spins'
      let iconImagePath = card.iconImagePath; // folosește calea inițială
      console.log("Card type:", card.card_type, "Prize:", card.prize);
      if (card.prize === "Free Spins") {
        console.log("Legendary Free Spins card detected");
        iconImagePath = "/assets/cards-icons/free_spins.png"; // actualizează calea pentru Legendary Free Spins
       
  if (additionalClass === "glow-premium") {
    cardImagePath = "/assets/cards-bg/card_legendary.png"; // calea către noua imagine
  }
        console.log("Updated iconImagePath:", iconImagePath);
      }
      cardElement.innerHTML = `
        <img src="${cardImagePath}" class="card-image">
        <img src="${iconImagePath}" class="card-icon">
        <div class="${cardDetailsClass}">
          ${card.won_amount} 
        </div>
      `;
      
      cardsDisplay.appendChild(cardElement);
      setTimeout(() => {
        cardElement.classList.add('fall-in');
        this.sound.play("clap", { volume: 0.5 });

      }, index * 250); // Ajustează delay-ul după nevoie
    });

    // Adaugă middleDiv în lootDiv
    lootDiv.appendChild(middleDiv);

    // Ajustează coloanele gridului dacă este necesar
    this.adjustGridColumns();
    lootDiv.classList.add("hide");
    lootDiv.classList.remove("hide");
    // if (window.innerWidth < 600) {
    //   lootDiv.classList.add("slide-in-bottom");
    //   calinBtn.classList.add("slide-in-bottom-claim");
    // } else {
    //   if (this.selectedPullType === "multiplier") {
    //     lootDiv.classList.add("slide-in-bottom-desktop-multiplier");
    //   } else {
    //     lootDiv.classList.add("slide-in-bottom-desktop");
    //   }
    //   calinBtn.classList.add("slide-in-bottom-claim-desktop");
    // }

    if (window.innerWidth < 600) {
      lootDiv.classList.add("slide-in-bottom");
      calinBtn.classList.add("slide-in-bottom-claim");
    } else if (window.innerWidth > 2000) {
      if (this.selectedPullType === "multiplier") {
        lootDiv.classList.add("slide-in-bottom-desktop-multiplier-4k");
      } else {
        lootDiv.classList.add("slide-in-bottom-desktop-multiplier-4k-normal");
      }
      calinBtn.classList.add("slide-in-bottom-claim-desktop-4k");
    } else {
      if (this.selectedPullType === "multiplier") {
        lootDiv.classList.add("slide-in-bottom-desktop-multiplier");
      } else {
        lootDiv.classList.add("slide-in-bottom-desktop");
      }
      calinBtn.classList.add("slide-in-bottom-claim-desktop");
      
    }
    const animationCard = document.querySelector(".animationcard");
    if (animationCard) {
      animationCard.remove();
    }
}

  adjustGridColumns() {
    const cardsDisplay = document.querySelector(".all-cards-display");

    // Aplică clasele bazate pe tipul selectat de pull
    if (
      this.selectedPullType === "regular" ||
      this.selectedPullType === "uncommon"
    ) {
      // Dacă tipul de pull este 'regular' sau 'uncommon', adaugă clasa 'three-cards'
      cardsDisplay.classList.add("three-cards");
      cardsDisplay.classList.remove("four-cards");
    } else if (
      this.selectedPullType === "multiplier" ||
      this.selectedPullType === "legendary"
    ) {
      // Dacă tipul de pull este 'multiplier' sau 'legendary', adaugă clasa 'four-cards'
      cardsDisplay.classList.add("four-cards");
      cardsDisplay.classList.remove("three-cards");
    } else {
      // Dacă nu se aplică niciuna dintre condiții, asigură-te că elementul nu are clase nedorite
      cardsDisplay.classList.remove("three-cards", "four-cards");
      cardsDisplay.style.gridTemplateColumns = `repeat(auto-fill, minmax(90px, 1fr))`;
    }

    const middleDiv = document.querySelector(".middle");
    if (!this.hasMultiplier) {
      middleDiv.style.paddingTop = "0";
    }
  }

  startAnimationSequence(lootbox) {
    this.playAnimation(lootbox, "in", false).then(() =>
      this.playAnimation(lootbox, "idle", true)
    );
    // .then(() => this.playAnimation(lootbox, 'idle', false)) // idle a second time
    // .then(() => this.playAnimation(lootbox, 'open', false))
    // .then(() => this.playAnimationForDuration(lootbox, 'idle_open', 3000)) // idle_open for 3 seconds
    // .then(() => this.playAnimationMultipleTimes(lootbox, 'open2', 5)) // open2 five times
    // .then(() => this.playAnimation(lootbox, 'close', false));
  }

  playAnimation(lootbox, animation, loop) {
    return new Promise((resolve) => {
      let trackEntry = lootbox.animationState.setAnimation(0, animation, loop);
      if (!loop) {
        trackEntry.listener = {
          complete: () => {
            resolve();
          },
        };
      } else {
        resolve();
      }
    });
  }
  fetchAndHandleRounds(pullType) {
    fetch(`https://api-dem.vercel.app/api/rounds/${pullType}`)
      .then((response) => response.json())
      .then((data) => {
        const roundsElement = document.querySelector(".rounds");
        if (roundsElement) {
          roundsElement.textContent = data.rounds;
          this.numRounds = data.rounds; // Actualizează proprietatea cu numărul de runde
          this.updateRoundsText(); // Actualizează textul pe scenă

        }
        console.log(`${pullType} rounds:`, data.rounds);
        // You can also handle additional game logic here depending on the rounds
      })
      .catch((error) => 
        console.error(`Error fetching ${pullType} rounds`, error)
      );
  }

  playAnimationForDuration(lootbox, animation, duration) {
    return new Promise((resolve) => {
      lootbox.animationState.setAnimation(0, animation, true);
      setTimeout(() => {
        resolve();
      }, duration);
    });
  }

  playAnimationMultipleTimes(lootbox, animation, times) {
    return new Promise((resolve) => {
      const playNext = (count) => {
        if (count >= times) {
          resolve();
          return;
        }
        this.playAnimation(lootbox, animation, false).then(() =>
          playNext(count + 1)
        );
      };
      playNext(0);
    });
  }
}
document.addEventListener("DOMContentLoaded", function () {
  // Apelul API pentru resetarea contoarelor
  fetch("https://api-dem.vercel.app/api/reset")
    .then((response) => response.json())
    .then((data) => {
      console.log(data.message); // Log the response from the reset API
      // Notifică restul aplicației că resetarea a avut loc și poate continua inițializarea
      // initializeGame();
    })
    .catch((error) => console.error("Error resetting counters:", error));
  // const r = new rive.Rive({
  //   src: "/background.riv",
  //   canvas: document.getElementById("canvass"),
  //   autoplay: true,
  //   animations: "Timeline 2",
  //   loot:true,
  //   onLoad: () => {
  //     r.resizeDrawingSurfaceToCanvas();
  //     console.log("Rive loaded");
  //   },
  // });
});

document.addEventListener("DOMContentLoaded", function () {
  /**
   * Checks if the user is using an iPad and adds a corresponding class to the body element.
   */
  if (navigator.userAgent.match(/iPad/i)) {
    document.body.classList.add("is-ipad");
  } else {
    document.body.classList.add("not-ipad");
  }

  const muteButton = document.getElementById("muteButton");
  let isMuted = false; // Variabilă pentru a urmări starea de mute

  muteButton.addEventListener("click", () => {
    isMuted = !isMuted; // Inversează starea de mute
    muteButton.classList.toggle("muted", isMuted); // Schimbă stilul butonului

    // Setează volumul global al sunetului în funcție de starea de mute
    Phaser.Sound.BaseSoundManager.prototype.mute = isMuted;
  });
  /**
   * Generates a random number of rounds (7 or 14) and displays them in the element with the 'rounds' class.
   */
  const roundsNumber = Math.random() < 0.5 ? 8 : 8;
  const roundsElement = document.querySelector(".rounds");
  if (roundsElement) {
    roundsElement.textContent = roundsNumber;
  }
  /**
   * Checks if the device is mobile and adjusts the game's width and height accordingly.
   */
  const isMobile = window.innerWidth < 800; // O simplă verificare; ajustează conform necesităților
  let gameWidth = window.innerWidth * window.devicePixelRatio;
  let gameHeight = window.innerHeight * window.devicePixelRatio;
  let scaleMode = Phaser.Scale.FIT; // Setează modul de scalare la RESIZE
  
  if (isMobile) {
    gameHeight = window.innerHeight * 1.4 * window.devicePixelRatio;
    gameWidth = window.innerWidth * 1.2 * window.devicePixelRatio;
  } else if (window.innerWidth > 1024) {
    gameWidth = 1024; // Fix width after 1024px
    gameHeight = 768; // Maintain aspect ratio or set a fixed height
    scaleMode = Phaser.Scale.ScaleManager; // Set ScaleManager for desktop
  } else {
    gameWidth = window.innerWidth * window.devicePixelRatio;
    gameHeight = window.innerHeight * window.devicePixelRatio;
  }
  
  console.log("height", gameHeight);
  console.log("width", gameWidth);
  
  if (isMobile) {
    // Ajustează înălțimea pentru dispozitive mobile
    gameHeight = window.innerHeight * 1.4 * window.devicePixelRatio; // Sau orice altă logică specifică
    gameWidth = window.innerWidth * 1.2 * window.devicePixelRatio;
  }
  
  /**
   * Configuration for Phaser.
   */
  const config = {
    width: gameWidth,
    height: gameHeight,
    type: Phaser.WEBGL,
    parent: "anime-container",
    scene: [SpineDemo],
    transparent: true,
    fps: {
      target: 60,
      forceSetTimeOut: false,
    },
    physics: {
      default: 'arcade',
      arcade: {
        debug: true
      }
    },
    plugins: {
      scene: [
        { key: "SpinePlugin", plugin: spine.SpinePlugin, mapping: "spine" },
      ],
    },
    scale: {
      mode: scaleMode,
      autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    render: {
      pixelArt: false,
      antialias: true,
    },
  };
  
  const game = new Phaser.Game(config);
  
  console.log(game.config);
  document.addEventListener('click', function() {
    // console.log('click');
    if (self.isLegendary) {
      console.log('isLegendary click');
      document.body.classList.add('skip-animation');
      this.animaionSkip = true;  // Setează animationSkip la true

    }
  });
});
let globalDeviceInfo;

document.addEventListener("DOMContentLoaded", function() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Detect Android
  if (/android/i.test(userAgent)) {
      document.body.classList.add('android');
  }

  // Detect iOS
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      document.body.classList.add('ios');
  }

  function detectMobileDevice() {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = md.mobile() !== null;
    const isTablet = md.tablet() !== null;
    const isPhone = md.phone() !== null;
    
    // Detectarea lățimii ecranului (smaller side)
    const smallerSide = Math.min(window.screen.width, window.screen.height);
  
    // Restul codului de detectare rămâne neschimbat
    let deviceType = 'desktop';
    if (isPhone) {
      deviceType = 'phone';
    } else if (isTablet) {
      deviceType = 'tablet';
    } else if (isMobile) {
      deviceType = 'mobile';
    }
  
    // Adăugăm smallerSide la rezultatul returnat
    return {
      deviceType: deviceType,
      isMobile: isMobile,
      isTablet: isTablet,
      isPhone: isPhone,
      smallerSide: smallerSide
    };
  }
  
  // Folosirea funcției
  const deviceInfo = detectMobileDevice();
  // console.log('Device Type:', deviceInfo.deviceType);
  // console.log('Is Mobile:', deviceInfo.isMobile);
  // console.log('Is Tablet:', deviceInfo.isTablet);
  // console.log('Is Phone:', deviceInfo.isPhone);
  // console.log('Smaller Side (Width):', deviceInfo.smallerSide);
  globalDeviceInfo = detectMobileDevice();
  console.log('Smaller Side (Width):', globalDeviceInfo.smallerSide);
  // Puteți folosi smallerSide pentru a ajusta layoutul sau comportamentul aplicației
  if (deviceInfo.smallerSide < 768) {
 
  } else {
    // Logică pentru ecrane mai mari
  }
//   document.addEventListener('click', (event) => {
//     console.log('Clicked element:', event.target);
// });
});